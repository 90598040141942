<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <v-row no-gutters class="mb-2">
      <v-col>
        <div class="headline">
          <v-icon>mdi-account-group</v-icon>
          Members
        </div>
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon left>mdi-account-plus</v-icon>
          New </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-account-group" prominent type="info">
          <div>
            <strong>Understanding Members in unstruct.ai: </strong>
            <ul>
              <li>
                RBAC (Role-Based Access Control) is used to manage access to resources in UnStruct.
              </li>
              <li>
                Members are users who have access to the organization's resources and can be assigned to projects.
              </li>
              <li>
                Members can be assigned different roles, such as Owner, Admin, Manager, and Member.
              </li>
              <li>
                Members can be assigned to multiple projects, and each project can have multiple members.
              </li>
            </ul>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card class="ma-4 elevation-2">
                <!-- Item Email and Role -->
                <v-card-title class="justify-space-between">
                  <div>
                    <v-icon large left>mdi-human-greeting</v-icon>
                    {{ item.email }}
                  </div>
                  <v-chip color="primary" text-color="white">
                    <v-icon left>{{ getRoleIcon(item.role) }}</v-icon>
                    {{ item.role }}
                  </v-chip>
                </v-card-title>

                <!-- Projects Information -->
                <v-card-text>
                  <div>
                    <v-icon left>mdi-folder-multiple-outline</v-icon>
                    Projects: {{ item.projects.length }}
                  </div>
                  <div v-for="project in item.projects" :key="project.id" class="my-2">
                    <project-card :project="project.project" />
                  </div>
                  <div>
                    <v-icon left>mdi-email</v-icon>
                    Email: {{ item.email }}
                  </div>
                </v-card-text>

                <!-- Actions -->
                <v-card-actions class="justify-end">
                  <v-btn color="primary" text @click="createEditShow(item)">View / Edit</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import NewEditSheet from "@/organization/OrganizationMemberNewEdit.vue"
import ProjectCard from "@/project/ProjectCard.vue"
export default {
  name: "OrganizationUserTable",

  components: {
    NewEditSheet,
    ProjectCard,
  },

  data() {
    return {
      headers: [
        { text: "Email", value: "email", sortable: true },
        { text: "Role", value: "role", sortable: false },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("auth", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
  },

  created() {
    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending],
      () => {
        this.page = 1
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("auth", ["getAll", "createEditShow"]),
    getRoleIcon(role) {
      switch (role) {
        case 'Owner': return 'mdi-crown';
        case 'Admin': return 'mdi-account-key';
        case 'Manager': return 'mdi-account-supervisor';
        case 'Member': return 'mdi-account';
        default: return '';
      }
    },
  },
}
</script>
