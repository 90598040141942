<template>
  <ValidationObserver v-slot="{ invalid, validated }">
    <v-navigation-drawer v-model="showCreateEdit" app clipped right width="500">
      <template #prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title v-if="id" class="title"> Edit </v-list-item-title>
            <v-list-item-title v-else class="title"> New </v-list-item-title>
            <v-list-item-subtitle>Member</v-list-item-subtitle>
          </v-list-item-content>
          <v-btn
            icon
            color="info"
            :loading="loading"
            :disabled="invalid || !validated"
            @click="save()"
          >
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="closeCreateEdit">
            <v-icon>close</v-icon>
          </v-btn>
        </v-list-item>
      </template>
      <v-card flat>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <span class="subtitle-2">Details</span>
              </v-flex>
              <v-flex xs12>
                <ValidationProvider name="Email" rules="required|email" immediate>
                  <v-text-field
                    v-model="email"
                    :disabled="id !== null"
                    label="Email"
                    hint="Member's email (required). An invitation will be sent to this address."
                    persistent-hint
                    :error-messages="errors"
                    slot-scope="{ errors, valid }"
                    :success="valid"
                  />
                </ValidationProvider>
              </v-flex>
              <v-flex xs12>
                <v-alert type="info" text dense>
                  A verification code will be generated and sent with the invitation email.
                </v-alert>
              </v-flex>
              <v-flex xs12>
                <v-tooltip max-width="300px" bottom>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="subtitle-2">Role</span>
                  </template>
                  <span>
                    This role applies to both the projects and the overall organization (or universe).
                    If you don't want the user to make changes to project settings, etc., an admin role is not needed.
                  </span>
                </v-tooltip>
              </v-flex>
              <v-flex xs12>
                <v-radio-group v-model="role" column>
                  <v-tooltip max-width="250px" left>
                    <template #activator="{ on, attrs }">
                      <v-radio v-bind="attrs" v-on="on" label="Member" value="Member"> </v-radio>
                    </template>
                    <span>
                      Members can view and act on interactive objects, as well as view most other data within
                      the organization.
                    </span>
                  </v-tooltip>
                  <v-tooltip max-width="250px" left>
                    <template #activator="{ on, attrs }">
                      <v-radio v-bind="attrs" v-on="on" label="Admin" value="Admin"> </v-radio>
                    </template>
                    <span>
                      Admin privileges on any teams of which they're a member. They can create new
                      teams and projects, as well as remove teams and projects which they already
                      hold membership on (or all teams, if open membership is on). Additionally,
                      they can manage memberships of teams that they are members of.
                    </span>
                  </v-tooltip>
                  <v-tooltip max-width="250px" left>
                    <template #activator="{ on, attrs }">
                      <v-radio v-bind="attrs" v-on="on" label="Manager" value="Manager"> </v-radio>
                    </template>
                    <span>
                      Gains admin access on all teams as well as the ability to add and remove
                      members.
                    </span>
                  </v-tooltip>
                  <v-tooltip max-width="250px" left>
                    <template #activator="{ on, attrs }">
                      <v-radio v-bind="attrs" v-on="on" label="Owner" value="Owner"> </v-radio>
                    </template>
                    <span
                      >Unrestricted access to the organization, its data, and its settings. Can add,
                      modify, and delete projects and members.</span
                    >
                  </v-tooltip>
                </v-radio-group>
              </v-flex>
              <v-flex xs12>
                <v-tooltip max-width="300px" bottom>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="subtitle-2">Settings</span>
                  </template>
                  <span>
                    Select any existing projects you are part of to share with the user, so they can collaborate on the documents for a particular project (or Galaxy).
                    If you want to keep something private, then a default project will be created for just this user and will be private to that user.
                  </span>
                </v-tooltip>
              </v-flex>
              <v-flex xs12>
                <v-list-item>
                  <v-list-item-content>
                    <project-combobox v-model="defaultProjects" label="Default Projects" />
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </ValidationObserver>
</template>

<script>
import { map } from "lodash"

import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import { ValidationObserver, ValidationProvider,  extend  } from "vee-validate"
import { required, email } from "vee-validate/dist/rules"


import ProjectCombobox from "@/project/ProjectCombobox.vue"

extend("email", email)
extend("required", {
  ...required,
  message: "This field is required",
})

export default {
  name: "MemberEditSheet",

  components: {
    ValidationObserver,
    ValidationProvider,
    ProjectCombobox,
  },

  computed: {
    ...mapFields("auth", [
      "selected.email",
      "selected.projects",
      "selected.role",
      "selected.id",
      "selected.loading",
      "dialogs.showCreateEdit",
    ]),

    defaultProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
      set(value) {
        let wrapped = map(value, function (item) {
          return { project: item, default: true }
        })
        this.projects = wrapped
        this.$emit("input", wrapped)
      },
    },
  },

  methods: {
    ...mapActions("auth", ["save", "closeCreateEdit"]),
  },
}
</script>
